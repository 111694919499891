import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";

//components
import Root from "./Root";

//home
const Home = React.lazy(() => import("../pages/Home"));

//landings
const index6 = React.lazy(() => import("../pages"));

//auth
// const Login = React.lazy(() => import('../pages/auth/Login'))
// const SignUp = React.lazy(() => import('../pages/auth/Signin'))

const loading = () => <div className=""></div>;

type LoadingComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadingComponentProps) => {
  return (
    <Suspense fallback={loading()}>
      <Component />
    </Suspense>
  );
};

const AllRoutes = () => {
  return useRoutes([
    {
      //root route
      path: "/",
      element: <LoadComponent component={index6} />,
    },
    // {
    //   //public routes
    //   path: "/",
    //   children: [
    //     { path: "index6", element: <LoadComponent component={index6} /> },
    //   ],
    // },
  ]);
};

export default AllRoutes;
